/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 100;
  src: local('Exo 2 Thin Italic'), local('Exo2-ThinItalic'), url(/assets/font/exo2/7cHqv4okm5zmbtYtE48s41ospT4.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 100;
  src: local('Exo 2 Thin Italic'), local('Exo2-ThinItalic'), url(/assets/font/exo2/7cHqv4okm5zmbtYtE48s6VospT4.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 100;
  src: local('Exo 2 Thin Italic'), local('Exo2-ThinItalic'), url(/assets/font/exo2/7cHqv4okm5zmbtYtE48s51os.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 200;
  src: local('Exo 2 Extra Light Italic'), local('Exo2-ExtraLightItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtEyM98HkJmAci.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 200;
  src: local('Exo 2 Extra Light Italic'), local('Exo2-ExtraLightItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtEyM98HMJmAci.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 200;
  src: local('Exo 2 Extra Light Italic'), local('Exo2-ExtraLightItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtEyM98H0JmA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 300;
  src: local('Exo 2 Light Italic'), local('Exo2-LightItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtE0c-8HkJmAci.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 300;
  src: local('Exo 2 Light Italic'), local('Exo2-LightItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtE0c-8HMJmAci.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 300;
  src: local('Exo 2 Light Italic'), local('Exo2-LightItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtE0c-8H0JmA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 400;
  src: local('Exo 2 Italic'), local('Exo2-Italic'), url(/assets/font/exo2/7cHov4okm5zmbtYtG-gc5UIo.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 400;
  src: local('Exo 2 Italic'), local('Exo2-Italic'), url(/assets/font/exo2/7cHov4okm5zmbtYtG-Ic5UIo.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 400;
  src: local('Exo 2 Italic'), local('Exo2-Italic'), url(/assets/font/exo2/7cHov4okm5zmbtYtG-wc5Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 500;
  src: local('Exo 2 Medium Italic'), local('Exo2-MediumItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtEx8_8HkJmAci.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 500;
  src: local('Exo 2 Medium Italic'), local('Exo2-MediumItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtEx8_8HMJmAci.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 500;
  src: local('Exo 2 Medium Italic'), local('Exo2-MediumItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtEx8_8H0JmA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 600;
  src: local('Exo 2 Semi Bold Italic'), local('Exo2-SemiBoldItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtEzM48HkJmAci.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 600;
  src: local('Exo 2 Semi Bold Italic'), local('Exo2-SemiBoldItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtEzM48HMJmAci.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 600;
  src: local('Exo 2 Semi Bold Italic'), local('Exo2-SemiBoldItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtEzM48H0JmA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 700;
  src: local('Exo 2 Bold Italic'), local('Exo2-BoldItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtE1c58HkJmAci.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 700;
  src: local('Exo 2 Bold Italic'), local('Exo2-BoldItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtE1c58HMJmAci.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 700;
  src: local('Exo 2 Bold Italic'), local('Exo2-BoldItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtE1c58H0JmA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 800;
  src: local('Exo 2 Extra Bold Italic'), local('Exo2-ExtraBoldItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtE0s68HkJmAci.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 800;
  src: local('Exo 2 Extra Bold Italic'), local('Exo2-ExtraBoldItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtE0s68HMJmAci.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 800;
  src: local('Exo 2 Extra Bold Italic'), local('Exo2-ExtraBoldItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtE0s68H0JmA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 900;
  src: local('Exo 2 Black Italic'), local('Exo2-BlackItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtE2878HkJmAci.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 900;
  src: local('Exo 2 Black Italic'), local('Exo2-BlackItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtE2878HMJmAci.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: italic;
  font-weight: 900;
  src: local('Exo 2 Black Italic'), local('Exo2-BlackItalic'), url(/assets/font/exo2/7cHtv4okm5zmbtYtE2878H0JmA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 100;
  src: local('Exo 2 Thin'), local('Exo2-Thin'), url(/assets/font/exo2/7cHov4okm5zmbt5LG-gc5UIo.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 100;
  src: local('Exo 2 Thin'), local('Exo2-Thin'), url(/assets/font/exo2/7cHov4okm5zmbt5LG-Ic5UIo.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 100;
  src: local('Exo 2 Thin'), local('Exo2-Thin'), url(/assets/font/exo2/7cHov4okm5zmbt5LG-wc5Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 200;
  src: local('Exo 2 Extra Light'), local('Exo2-ExtraLight'), url(/assets/font/exo2/7cHrv4okm5zmbt7nCvs_wH8RnA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 200;
  src: local('Exo 2 Extra Light'), local('Exo2-ExtraLight'), url(/assets/font/exo2/7cHrv4okm5zmbt7nCvs1wH8RnA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 200;
  src: local('Exo 2 Extra Light'), local('Exo2-ExtraLight'), url(/assets/font/exo2/7cHrv4okm5zmbt7nCvs7wH8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 300;
  src: local('Exo 2 Light'), local('Exo2-Light'), url(/assets/font/exo2/7cHrv4okm5zmbt6DCfs_wH8RnA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 300;
  src: local('Exo 2 Light'), local('Exo2-Light'), url(/assets/font/exo2/7cHrv4okm5zmbt6DCfs1wH8RnA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 300;
  src: local('Exo 2 Light'), local('Exo2-Light'), url(/assets/font/exo2/7cHrv4okm5zmbt6DCfs7wH8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  src: local('Exo 2'), local('Exo2-Regular'), url(/assets/font/exo2/7cHmv4okm5zmbtYsK-4E4Q.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  src: local('Exo 2'), local('Exo2-Regular'), url(/assets/font/exo2/7cHmv4okm5zmbtYmK-4E4Q.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  src: local('Exo 2'), local('Exo2-Regular'), url(/assets/font/exo2/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 500;
  src: local('Exo 2 Medium'), local('Exo2-Medium'), url(/assets/font/exo2/7cHrv4okm5zmbt7bCPs_wH8RnA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 500;
  src: local('Exo 2 Medium'), local('Exo2-Medium'), url(/assets/font/exo2/7cHrv4okm5zmbt7bCPs1wH8RnA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 500;
  src: local('Exo 2 Medium'), local('Exo2-Medium'), url(/assets/font/exo2/7cHrv4okm5zmbt7bCPs7wH8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 600;
  src: local('Exo 2 Semi Bold'), local('Exo2-SemiBold'), url(/assets/font/exo2/7cHrv4okm5zmbt73D_s_wH8RnA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 600;
  src: local('Exo 2 Semi Bold'), local('Exo2-SemiBold'), url(/assets/font/exo2/7cHrv4okm5zmbt73D_s1wH8RnA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 600;
  src: local('Exo 2 Semi Bold'), local('Exo2-SemiBold'), url(/assets/font/exo2/7cHrv4okm5zmbt73D_s7wH8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  src: local('Exo 2 Bold'), local('Exo2-Bold'), url(/assets/font/exo2/7cHrv4okm5zmbt6TDvs_wH8RnA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  src: local('Exo 2 Bold'), local('Exo2-Bold'), url(/assets/font/exo2/7cHrv4okm5zmbt6TDvs1wH8RnA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  src: local('Exo 2 Bold'), local('Exo2-Bold'), url(/assets/font/exo2/7cHrv4okm5zmbt6TDvs7wH8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 800;
  src: local('Exo 2 Extra Bold'), local('Exo2-ExtraBold'), url(/assets/font/exo2/7cHrv4okm5zmbt6PDfs_wH8RnA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 800;
  src: local('Exo 2 Extra Bold'), local('Exo2-ExtraBold'), url(/assets/font/exo2/7cHrv4okm5zmbt6PDfs1wH8RnA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 800;
  src: local('Exo 2 Extra Bold'), local('Exo2-ExtraBold'), url(/assets/font/exo2/7cHrv4okm5zmbt6PDfs7wH8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 900;
  src: local('Exo 2 Black'), local('Exo2-Black'), url(/assets/font/exo2/7cHrv4okm5zmbt6rDPs_wH8RnA.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 900;
  src: local('Exo 2 Black'), local('Exo2-Black'), url(/assets/font/exo2/7cHrv4okm5zmbt6rDPs1wH8RnA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 900;
  src: local('Exo 2 Black'), local('Exo2-Black'), url(/assets/font/exo2/7cHrv4okm5zmbt6rDPs7wH8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

