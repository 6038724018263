@import "../../../node_modules/@angular/material/theming";
@import "./exo2";
@import "./tajawal";
$input-color: var(--input-color,#c0e694);
$moome-color: var(--moome-color,#80cc28);
$clear-blue: #316a90;
$dark-blue: #204157;
$clear-red: #f44336;
$clear-black: #444444;
$moome-font: "Exo 2";
$dark-dividers: rgba($dark-blue, 0.12);
$light-grey: #f2f2f2;
$light: #ffffff;
$page-color: #f6f7fb;
$backgroud-input: #fcfcfd;
$dark-primary-text: rgba($dark-blue, 0.87);
$dark-secondary-text: rgba($dark-blue, 0.54);
$dark-disabled-text: rgba($dark-blue, 0.38);
$dark-focused: rgba($dark-blue, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$moome-palette: (
  50: var(--50,#f0f9e5),
  100: var(--100,#d9f0bf),
  200: $input-color,
  300: var(--300,#a6db69),
  400: var(--400,#93d448),
  500: $moome-color,
  600: var(--600,#78c724),
  700: var(--700,#6dc01e),
  800: var(--800,#63b918),
  900: var(--900,#50ad0f),
  A100: var(--A100,#e9ffdc),
  A200: var(--A200,#c9ffa9),
  A400: var(--A400,#a9ff76),
  A700: var(--A700,#99ff5d),
  contrast: (
    50: $dark-blue,
    100: $dark-blue,
    200: $dark-blue,
    300: $dark-blue,
    400: $dark-blue,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $dark-blue,
    A200: $dark-blue,
    A400: $dark-blue,
    A700: $dark-blue
  )
);

// Foreground palette for light themes.
$moome-light-theme-foreground: (
  base: $dark-blue,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $clear-blue,
  disabled-button: rgba($dark-blue, 0.26),
  disabled-text: $clear-blue,
  elevation: $dark-blue,
  hint-text: $clear-blue,
  secondary-text: $clear-blue,
  icon: rgba($dark-blue, 0.54),
  icons: rgba($dark-blue, 0.54),
  text: rgba($dark-blue, 0.87),
  slider-min: rgba($dark-blue, 0.87),
  slider-off: rgba($dark-blue, 0.26),
  slider-off-active: rgba($dark-blue, 0.38)
);

$moome-light-theme-background: (
  status-bar: map_get($mat-grey, 300),
  app-bar: map_get($mat-grey, 100),
  background: map_get($mat-grey, 50),
  hover: rgba(black, 0.04),
  card: white,
  dialog: white,
  disabled-button: rgba(black, 0.12),
  raised-button: white,
  focused-button: $dark-focused,
  selected-button: map_get($mat-grey, 300),
  selected-disabled-button: map_get($mat-grey, 400),
  disabled-button-toggle: map_get($mat-grey, 200),
  unselected-chip: map_get($mat-grey, 300),
  disabled-list-option: map_get($mat-grey, 200)
);

$moomy-secondary-palette: (
  50: #e4e8eb,
  100: #bcc6cd,
  200: #90a0ab,
  300: #637a89,
  400: #415e70,
  500: $clear-blue,
  600: #1c3b4f,
  700: #183246,
  800: #132a3c,
  900: #0b1c2c,
  A100: #69b1ff,
  A200: #3696ff,
  A400: #037cff,
  A700: #006fe8,
  contrast: (
    50: $dark-blue,
    100: $dark-blue,
    200: $dark-blue,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: $dark-blue,
    A200: $dark-blue,
    A400: #ffffff,
    A700: #ffffff
  )
);

$white-palette: (
  50: #ffffff,
  100: #ffffff,
  200: #ffffff,
  300: #ffffff,
  400: #ffffff,
  500: #ffffff,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: $dark-blue,
    100: $dark-blue,
    200: $dark-blue,
    300: $dark-blue,
    400: $dark-blue,
    500: $dark-blue,
    600: $dark-blue,
    700: $dark-blue,
    800: $dark-blue,
    900: $dark-blue,
    A100: $dark-blue,
    A200: $dark-blue,
    A400: $dark-blue,
    A700: $dark-blue
  )
);
.login-container {
  width: 100%;
  height: 100%;
}

.admin-login {
  background: rgba(white, 1) url(/assets/images/admin/administrator.png) no-repeat right;
  background-size: contain;
}
.farmer-login {
  background: rgba(white, 1) url(/assets/images/farmer/login-farmer.png) no-repeat right;
  background-size: contain;
}
.vet-login {
  background: rgba(white, 1) url(/assets/images/veterinary/doctor.png) no-repeat right;
  background-size: contain;
}

.close-dialog {
  float: right;
}

.rtl {
  direction: rtl;
  font-family: "Tajawal";
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  margin-bottom: 5px;
}
.mat-form-field {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .scroll-dialog {
    max-height: 500px !important;
    overflow: auto;
  }
}

.cdk-drag-placeholder {
  display: none;
}

.cdk-drag-preview {
  opacity: 0.7;
}

.mat-elevation-z8 {
  width: 100%;
  margin: auto;
  position: relative;
}
.mat-elevation-z88 {
  width: 96%;
  margin: auto;
}
.space {
  padding-left: 200px;
}
table {
  width: 100%;
}
.containerTab {
  max-height: 450px;
  overflow: auto;
}
.style-icon {
  display: inline-block;
  position: relative;
  top: -1px;
  right: 10px;
}
.style-span {
  position: relative;
  top: -1px;
  right: 8px;
}

.mat-sort-header-container {
  align-items: center;
}

.green-color {
  color: green;
}

.red-color {
  color: red;
}

.search_style {
  line-height: 3em;
  padding-left: 15px;
  width: 100%;
}

.case {
  box-shadow: 1px 1px 10px;

  max-height: 160px;
  max-width: 100%;
  padding: 0px;
  margin: 5% 5% 0% 5%;
  cursor: pointer;
}

.style-card {
  padding: 15px;
  margin-bottom: 50px;
  margin-top: 50px;
}

::ng-deep .mat-expansion-panel-header[aria-disabled="true"] {
  color: $dark-primary-text !important;
}

.note-style-ar {
  position: relative;
  right: 6px;
}
.note-style {
  position: relative;
  right: 6px;
}

.note-style1-ar {
  position: relative;
  left: 22;
  top: 10px;
}
.note-style1 {
  position: relative;
  top: 10px;
}
.menu-style-ar {
  position: relative;
  left: 6px;
  top: 3px;
  font-family: "Tajawal";
}

.menu-style {
  position: relative;
  top: 3px;
  right: 6px;
}

.menu-style1-ar {
  position: relative;
  left: 17px;
  top: 3px;
  font-family: "Tajawal";
}

.menu-style1 {
  position: relative;
  top: 3px;
  left: 5px;
}

.icon-position {
  position: relative;
  float: right;
}

.icon-position-ar {
  position: relative;
  float: left;
}

.active-color {
  border: black solid 3px !important;
}

.padding-ar {
  margin: 0px 0px 0px 9px !important;
}

.padding-fr-en {
  margin: 0px 9px 0px 0px !important;
}

.hover-style:hover {
  opacity: 0.7;
}

.loop-style {
  position: relative;
  left: 32px;
  top: 3px;
  z-index: 1;
}
.loop-style-ar {
  position: relative;
  right: 23px;
  top: 3px;
  z-index: 1;
}

.accordion-style {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.panel-header-style {
  padding: 0px 15px 0px 15px;
}

.style-icon-add {
  position: relative;
  left: 10px;
  top: 3px;
}

.style-icon-add-ar {
  position: relative;
  right: -10px;
  top: 4px;
}

@media only screen and (max-width: 600px) {
  .style-icon-add {
    position: relative;
    left: 24px;
    top: 3px;
  }

  .style-icon-add-ar {
    position: relative;
    right: 0px;
    top: 4px;
  }
}

.search-style {
  text-indent: 0;
  width: 88px;
  padding: 7px 14px 7px 30px;
  border-radius: 4px;
  margin-left: 5px;
  border: none;
  // position: relative;
  // top: -1px;
  //  left: 15px;
  font-family: "Exo 2";
  font-size: 14px;
  font-weight: 500;
  display: table-footer-group;
  &::placeholder {
    color: $dark-primary-text;
  }
}

.search-style-ar {
  font-family: "Tajawal";
  text-indent: 0;
  width: 44px;
  padding: 7px 30px 7px 14px;
  border-radius: 4px;
  border: none;
  /* position: relative;
  top: 3px;
  left: 15px;*/
  font-size: 14px;
  font-weight: 500;
  display: table-footer-group;
  &::placeholder {
    color: $dark-primary-text;
  }
}

.style-font-family {
  font-family: "Tajawal";
}

::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $moome-color;
}

mat-checkbox-inner-container mat-checkbox-inner-container-no-side-margin {
  background-color: $moome-color;
}

.mat-datepicker-toggle-default-icon {
  width: 2em !important;
}

.button-style {
  margin-right: 5px;
}
.style-align {
  display: inline-block;
  position: relative;
  top: 1px;
  left: 4px;
}
.style-icon {
  display: inline-block;
  position: relative;
  top: -1px;
  right: 10px;
}
.style-span {
  position: relative;
  top: -1px;
  right: 8px;
}

.mat-header-cell {
  text-align: center;
  justify-content: center;
}

::ng-deep .mat-sort-header-container {
  display: flex;
  justify-content: center;
}

::ng-deep .owl-dots {
margin-top: -115px
}
.icon-button-style-svg-ar {
  position: relative;
  left: 6px;
  top: 6px;
  height: 24px;
}
.icon-button-style-svg {
  position: relative;
  top: 6px;
  height: 24px;
}

.message-style {
  font-size: 25px;
  color: $dark-blue;
  opacity: 0.7;
}

.bold {
  font-weight: bold;
}
::ng-deep .custom-tooltip {
  background-color:white;
  height: auto;
  color: $dark-blue !important;
  font-weight: 400;
  border-radius: 5px;
  font-size: 13px;
  padding: 8px;
  text-align: center;
  box-shadow: 0 1px 3px 0 rgb(28 28 28 / 35%);
}

.myPanelClass{
  margin: 40px 0px;
}

.myPanelAnimalClass{
  margin: 70px -8px;
}

.myPanelSettingClass{
  margin: 50px 0px;
}

.myPanelSettingLabelClass{
  margin: 50px 0px;
  margin-left: -9px;
  width: 267px;
}

.myPanelSettingFarmerClass {
  margin: 50px 0px;
  width: 244px;
  height: 162px;
}
.male-style {
  color: #6282d9;
}

.female-style {
  color: #e68be3;
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0);}
  
.text-center {
   text-align: center;
  }

.animalClass {
  margin: 50px 0px;
  margin-left: 18px;
  min-width: 212px !important;
}

.gatewayClass {
  margin-left: 25px;
  margin-right: -25px;

} 
.myPanelCountryClass{
  margin-top: 47px !important; 

}
.margin{
  margin-left: 0px;
}
